<template>
  <div class="levelFourPage">
    <!-- 头部 -->
    <swipeTop :top_img="top_img" :ZHtitle="ZHtitle" :ENtitle="ENtitle" />
    <!-- 搜索框 -->
    <seachVue />
    <!-- 分类导航栏 -->
    <classifyList :toptype="toptype" :checkindex="checkindex" />
    <!-- 内容 -->
    <div class="content">
      <div class="backColor">
        <div
          @click="toChilder(item)"
          v-for="(item, index) in itemList"
          :key="index"
          class="item"
        >
          <van-image width="30" height="30" :src="item.pic"></van-image>
          <span>{{ item.menuName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swipeTop from "@/components/swipeTop.vue";
import seachVue from "@/components/seachVue.vue";
import classifyList from "@/components/classifyList.vue";
import { genMenuInfo } from "@/api/path";
export default {
  components: {
    swipeTop,
    seachVue,
    classifyList,
  },
  data() {
    return {
      menuId: this.$route.query.menuId,
      checkindex: Number(this.$route.query.checkindex),
      top_img: "", // 头部背景图片
      ZHtitle: "", // 中文标题
      ENtitle: "", //  英文标题
      itemList: [],
      toptype: [], // 导航标签数据
    };
  },
  created() {
    this.getAllMenu();
  },
  methods: {
    // 获取本页面基本信息
    getAllMenu() {
      genMenuInfo(this.menuId)
        .then((res) => {
          this.ZHtitle = res.data.menuName; // 获取中文标题
          this.top_img = res.data.background; // 获取头部背景图片
          this.ENtitle = res.data.menuEnglish; // 英文标题
          this.itemList = res.data.subMenuList;
          this.toptype = res.data.classificationMessageAlways; // 获取导航标签数据
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 五级页面
    toChilder(item) {
      this.$router.push({
        path: "/levelFivepage",
        query: { menuId: item.menuId, checkindex: this.checkindex },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.levelFourPage {
  height: 100vh;
  .content {
    height: 61%;
    background-color: #8e0a10;
    overflow: scroll;
    .backColor {
      background: #bf545a;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      .item {
        border: 1px solid #c56e72;
        display: flex;
        width: 30%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 15vh;
        margin: 10px 0 10px 15px;
        border-radius: 10px;
        background-color: #c56e72;
        background-size: 100% 100%;
        span {
          text-align: center;
          width: 80%;
          margin: 0 auto;
          font-size: 3.2vw;
          color: white;
          margin-top: 15px;
        }
      }
    }
  }
}
</style>